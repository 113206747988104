<template>
    <div class="options-tabs">

      <!-- Contenedor de Pestañas -->

  <div class="tabs">
  <button 
    @click="activeTab = 'calls'" 
    :class="{ active: activeTab === 'calls' }"
    :aria-selected="activeTab === 'calls' ? 'true' : 'false'">
    Opciones CALL
  </button>
  <button 
    @click="activeTab = 'puts'" 
    :class="{ active: activeTab === 'puts' }"
    :aria-selected="activeTab === 'puts' ? 'true' : 'false'">
    Opciones PUT
  </button>
  <button 
    @click="activeTab = 'neutrals'" 
    :class="{ active: activeTab === 'neutrals' }"
    :aria-selected="activeTab === 'neutrals' ? 'true' : 'false'">
    Opciones Neutrales
  </button>
</div>
  
      <!-- Pestaña de Opciones CALL -->
      <div v-if="activeTab === 'calls'">
        <div v-if="calls.length === 0">No hay opciones CALL disponibles</div>
        <div v-if="calls.length > 0">
          <h3>Actividad Relevante en Opciones CALL</h3>
          <div class="option-cards">
            <div v-for="option in calls" :key="option.ticker" class="option-card">
              <h4>
                <a :href="'https://finviz.com/quote.ashx?t=' + option.ticker" target="_blank" class="asset-link">
                  {{ option.ticker }}
                </a>
              </h4>
              <p>{{ option.recommendation }}</p>
              <p>{{ option.description }}</p>
              <p>Strike: ${{ option.strikePriceFloat }} | Precio Actual: ${{ option.underlyingPriceFloat }}</p>
              <p>Hora de la Alerta: {{ option.time }}</p>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Pestaña de Opciones PUT -->
      <div v-if="activeTab === 'puts'">
        <div v-if="puts.length === 0">No hay opciones PUT disponibles</div>
        <div v-if="puts.length > 0">
          <h3>Actividad Relevante en Opciones PUT</h3>
          <div class="option-cards">
            <div v-for="option in puts" :key="option.ticker" class="option-card">
              <h4>
                <a :href="'https://finviz.com/quote.ashx?t=' + option.ticker" target="_blank" class="asset-link">
                  {{ option.ticker }}
                </a>
              </h4>
              <p>{{ option.recommendation }}</p>
              <p>{{ option.description }}</p>
              <p>Strike: ${{ option.strikePriceFloat }} | Precio Actual: ${{ option.underlyingPriceFloat }}</p>
              <p>Hora de la Alerta: {{ option.time }}</p>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Pestaña de Opciones Neutrales -->
      <div v-if="activeTab === 'neutrals'">
        <div v-if="neutrals.length === 0">No hay opciones neutrales disponibles</div>
        <div v-if="neutrals.length > 0">
          <h3>Opciones Neutrales </h3>
          <div class="option-cards">
            <div v-for="option in neutrals" :key="option.ticker" class="option-card">
              <h4>
                <a :href="'https://finviz.com/quote.ashx?t=' + option.ticker" target="_blank" class="asset-link">
                  {{ option.ticker }}
                </a>
              </h4>
              <p>{{ option.recommendation }}</p>
              <p>{{ option.description }}</p>
              <p>Strike: ${{ option.strikePriceFloat }} | Precio Actual: ${{ option.underlyingPriceFloat }}</p>
              <p>Hora de la Alerta: {{ option.time }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

import axios from "axios";


  export default {
    data() {
      return {
        activeTab: 'calls', // Pestaña activa por defecto
        calls: [],  // Aquí almacenaremos las opciones CALL
        puts: [],   // Aquí almacenaremos las opciones PUT
        neutrals: [] // Aquí almacenaremos las opciones neutrales
      };
    },

    methods: {
      async fetchOptions() {
  // Obtengo el Token y lo verifico
  const token = localStorage.getItem("token");

  if (!token) {
    console.error("No se encontró el token de sesión.");
    this.logout();
    return (window.location.href = "/login");
  }

  // Validar el token antes de hacer la solicitud
  try {
    const decoded = JSON.parse(atob(token.split(".")[1])); // Decodificar payload del JWT
    const exp = decoded.exp * 1000; // Convertir a milisegundos

    if (Date.now() >= exp) {
      console.error("El token ha expirado. Cerrando sesión.");
      this.logout();
      return (window.location.href = "/login");
    }
  } catch (error) {
    console.error("Error al decodificar el token. Cerrando sesión.");
    this.logout();
    return (window.location.href = "/login");
  }

  // Procedo a solicitar los datos de las Opciones
  try {
    const response = await axios.get("https://api.fortunia.org/api/options/", {
      headers: { Authorization: `Bearer ${token}` },
    });

    // Verifica si la respuesta contiene los datos esperados
    console.log(response.data); // Para revisar la respuesta real

    // Asegúrate de que la respuesta contenga los datos correctamente
    if (response.data.message === 'Datos de opciones obtenidos exitosamente') {
      this.calls = response.data.data.calls; // Cargar las opciones CALL
      this.puts = response.data.data.puts || []; // Cargar las opciones PUT, si existen
      this.neutrals = response.data.data.neutrals || []; // Cargar las opciones Neutrales, si existen
    } else {
      console.error('Respuesta inesperada:', response.data);
    }
  } catch (error) {
    console.error('Error al obtener las opciones:', error);
  }
 }
},



    mounted() {
        this.fetchOptions();
        this.interval = setInterval(() => {
        this.fetchOptions();
      }, 300000);
    },

    beforeUnmount() {
       clearInterval(this.interval);
    }


  };
  </script>
  
  
  <style scoped>
  .tabs {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  button {
    background-color: #f3f4f6;
    border: 2px solid #d1d5db;
    color: #333;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    max-width: 200px;
    text-align: center;
  }
  
  button:hover {
    background-color: #4caf50;
    color: white;
    border-color: #4caf50;
  }
  
  button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  }


  .option-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
  }
  
  .option-card {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .option-card h4 {
    margin: 0;
  }
  
  .asset-link {
    text-decoration: none;
    color: #007bff;
  }
  
  .asset-link:hover {
    text-decoration: underline;
  }


  .option-card p:nth-of-type(2) {
  color: #28a745; /* Verde éxito */
  font-weight: bold;
  font-size: 1.1em;
  background-color: #e6ffe6; /* Fondo verde suave */
  padding: 5px;
  border-radius: 5px;
}


  </style>