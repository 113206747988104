<template>
  <div class="transmissions-container">
    <header class="header">
      <img class="logo" src="https://www.fortunia.org/img/logo.0484ebc9.png" alt="Fortunia Logo" />
      <h1>Transmisiones en Vivo</h1>
    </header>

    <div v-if="!isLoggedIn" class="message error">
      <p>Debes iniciar sesión para ver las transmisiones en vivo.</p>
    </div>

    <div v-else class="content">
      <p class="welcome">Bienvenido, disfruta de nuestras transmisiones en vivo:</p>
      <div class="video-container">
        <iframe v-if="isLive" :src="videoUrl" title="Transmisión en Vivo" allowfullscreen></iframe>
        <p v-else class="message">No hay transmisiones en vivo disponibles en este momento.</p>
      </div>
    </div>

    <button @click="goToHomePage" class="home-button">Volver al Inicio</button>

    <!-- Pie de página -->
     <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><router-link to="/privacy">Política de Privacidad</router-link></li>
          <li><router-link to="/disclaimer">Disclaimers</router-link></li>
          <li><router-link to="/about">Acerca de</router-link></li>
          <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
  
        </ul>
      </nav>
    </footer>

  </div>

  
</template>


<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoggedIn: false,
      isLive: false,
      videoUrl: "",
      tokenVerificationInterval: null,
    };
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      this.verifyToken(token);
    }
    this.fetchLiveStatus(); // Verifica si hay transmisión al cargar el componente
    this.startTokenVerification(); // Inicia la verificación periódica del token
  },
  beforeUnmount() {
    // Detenemos la verificación periódica al destruir el componente
    clearInterval(this.tokenVerificationInterval);
  },
  methods: {


 async verifyTokenStatus() {
  const token = localStorage.getItem("token");

  if (!token) {
    console.error("No se encontró el token de sesión.");
    this.logout();
    return (window.location.href = "/login");
  }

  // Validar el token antes de hacer la solicitud
  try {
    const decoded = JSON.parse(atob(token.split(".")[1])); // Decodificar payload del JWT
    const exp = decoded.exp * 1000; // Convertir a milisegundos

    if (Date.now() >= exp) {
      console.error("El token ha expirado. Cerrando sesión.");
      this.logout();
      return (window.location.href = "/login");
    }
  } catch (error) {
    console.error("Error al decodificar el token. Cerrando sesión.");
    this.logout();
    return (window.location.href = "/login");
  }

  // Si el token es válido, proceder con la verificación en el backend
  try {
    const response = await axios.get("https://api.fortunia.org/api/users/verify-token-status", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log("Respuesta de verificación de token:", response.data);

    if (!response.data.isValid) {
      console.error("Token inválido. Cerrando sesión.");
      this.logout();
      window.location.href = "/login";
    } else {
      console.log("Token válido.");
    }
  } catch (error) {
    console.error("Error de red al verificar el token:", error.message);
    this.logout();
  }
},

logout() {
    axios
      .post("https://api.fortunia.org/api/auth/logout")
      .then(() => {
        // Limpia el localStorage
        localStorage.removeItem("token");
        localStorage.removeItem("role");
  
        // Redirige al usuario a la página de inicio
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error al cerrar sesión:", error);
  
        // Asegúrate de limpiar y redirigir incluso si hay un error
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        window.location.href = "/";
      });
  },
  

    async verifyToken(token) {
      try {
        const response = await axios.get(
          "https://api.fortunia.org/api/auth/verify-token",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        this.isLoggedIn = response.data.isValid;
        if (!this.isLoggedIn) {
          this.isLive = false;
          this.videoUrl = "";
        }
      } catch (error) {
        console.error("Error al verificar el token:", error);
        this.isLoggedIn = false;
      }
    },


    startTokenVerification() {
      const token = localStorage.getItem("token");
      
     if (!token) {
        console.error("No se encontró el token de sesión.");
         this.logout();
         return (window.location.href = "/login");
     }


      this.tokenVerificationInterval = setInterval(() => {
        if (token) {
          this.verifyToken(token);
        }
      }, 5000); // Verifica cada 5 segundos
    },
    async fetchLiveStatus() {
      try {
        const authToken = localStorage.getItem('token');
        if (!authToken) {
          console.error("Token de autenticación no encontrado");
          return;
        }

        const config = {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        };

        const response = await axios.get("https://api.fortunia.org/api/owncast/status", config);
        const isCurrentlyLive = response.data.status === "En línea";

        this.isLive = isCurrentlyLive;

        if (isCurrentlyLive) {
          this.videoUrl = "https://streaming.fortunia.org/embed/video"; // URL de la transmisión en vivo
        }
      } catch (error) {
        console.error("Error al obtener el estado de las transmisiones:", error);
        this.isLive = false; // En caso de error, se asegura que no haya transmisión activa
      }
    },
    goToHomePage() {
      window.location.href = "/";
    },
  },
};
</script>


<style scoped>
.transmissions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  background: linear-gradient(135deg, #f7f7f7, #e0e0e0);
  min-height: 100vh;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.logo {
  width: 120px;
  margin-bottom: 15px;
}

h1 {
  font-size: 2.2rem;
  color: #222;
}

.message {
  font-size: 1.2rem;
  margin: 20px 0;
}

.error {
  color: red;
}

.video-container {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.video-container iframe {
  width: 100%;
  height: 450px;
  border: none;
}

.home-button {
  margin-top: 30px;
  padding: 12px 25px;
  background: #007BFF;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.home-button:hover {
  background: #0056b3;
}

/* Pie de Página */
.footer {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--secondary-color);
  text-align: center;
  border-top: 1px solid #ddd;
  font-size: 14px;
  color: #030303;
}

.footer p {
  margin: 5px 0;
}

.footer nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.footer nav ul li {
  margin: 5px 0;
}

.footer nav ul li a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 14px;
}

.footer nav ul li a:hover {
  color: #000;
}

/* Estilos específicos para pantallas pequeñas */
@media (max-width: 768px) {
  .footer {
    font-size: 12px;
    padding: 10px;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer nav ul li a {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer {
    font-size: 10px;
    padding: 8px;
  }

  .footer nav ul li a {
    font-size: 10px;
  }
}


@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }
  .video-container iframe {
    height: 350px;
  }
  .home-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }
  .video-container iframe {
    height: 250px;
  }
  .home-button {
    font-size: 0.8rem;
    padding: 8px 16px;
  }
}
</style>
